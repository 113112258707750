<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>Menu</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Menu</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content" v-if="has_permission === true">
      <div class="container-fluid">
        <div class="card">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    <el-button-group class="mr-3">
                      <!--                  <el-button type="primary" icon="el-icon-plus" @click="expandAll()">Expand</el-button>
                    <el-button type="primary" icon="el-icon-minus" @click="collapseAll()">Collapse</el-button>-->
                      <el-button
                        class="btn btn-common"
                        icon="el-icon-s-grid"
                        @click="toggleTreeView()"
                      >Expand/Collapse</el-button
                      >
                    </el-button-group>
                  </h3>
                  <div class="card-tools">
                    <el-button-group>
                      <el-button type="success" @click="updateOrder()"
                      ><i class="fas fa-check"></i> <span class="d-none d-sm-inline-flex">Save</span></el-button
                      >
                      <el-button type="warning" @click="refresh()">
                        <i class="fas fa-sync-alt"></i> <span class="d-none d-sm-inline-flex">Refresh</span></el-button
                      >
                    </el-button-group>
                  </div>
                </div>
                <div class="card-body">
                  <Tree :value="menuItems" :maxLevel="3">
                    <span slot-scope="{ node, index, path, tree }">
                      <b
                        @click="tree.toggleFold(node, path)"
                        style="cursor: pointer"
                      >
                        <i class="fas fa-arrows-alt"></i>
                        {{ node.$folded ? "+" : "-" }}
                      </b>
                      <!--                  <b>{{ index }}</b>-->
                      <!--                  Title: -->
                      <span>{{ node.title }}</span>
                      <!--                  - Path: <i>{{ path.join(',') }}</i>-->

                      <div class="menu-action">
                        <el-button
                          type="primary"
                          size="small"
                          icon="el-icon-edit"
                          circle
                          @click="edit(node.id)"
                        ></el-button>
                        <el-button
                          type="danger"
                          size="small"
                          icon="el-icon-delete"
                          circle
                          @click="destroy(node.id)"
                        ></el-button>
                      </div>
                    </span>
                  </Tree>
                </div>
                <div class="card-footer"></div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title mb-3">New</h3>
                </div>
                <div class="card-body table-responsive">
                  <form
                    method="POST"
                    novalidate
                    @submit.prevent="handleSubmission()"
                  >
                    <div class="">
                      <div class="form-group">
                        <label for="parent_id" class="control-label"
                        >Parent</label
                        >
                        <!--                    <multiselect id="parent" name="parent" :options="Object.keys(pre.menu_items_for_dropdown)" :custom-label="opt => pre.menu_items_for_dropdown[opt]"
                                   v-bind:class="{'has-error' : errors.has('parent')}"
                                   v-validate="'required'"
                                   v-model="value.parent" class="parent"
                                   placeholder="Select dropdown_test" :searchable="true"
                                   :close-on-select="true" :show-labels="false"></multiselect>-->

                        <select
                          v-model="value.parent_id"
                          id="parent_id"
                          name="parent_id"
                          class="form-control parent_id"
                          v-bind:class="{ 'has-error': errors.has('parent_id') }"
                          v-validate="'required'"
                        >
                          <option
                            v-for="(
                              menu_option, key
                            ) in pre.menu_items_for_dropdown"
                            v-bind:value="key"
                            v-html="menu_option"
                          ></option>
                        </select>

                        <div
                          class="help text-danger"
                          v-show="errors.has('parent_id')"
                        >
                          {{ errors.first("parent_id") }}
                        </div>
                      </div>
                      <div class="form-group required">
                        <label for="title" class="control-label">Title</label>
                        <input
                          type="text"
                          id="title"
                          name="title"
                          v-validate="'required'"
                          class="form-control"
                          :class="{ 'has-error': errors.has('title') }"
                          placeholder="Name"
                          v-model="value.title"
                        />
                        <div
                          class="help text-danger"
                          v-show="errors.has('title')"
                        >
                          {{ errors.first("title") }}
                        </div>
                      </div>
                      <div class="form-group required">
                        <label for="icon" class="control-label">Icon</label>
                        <vfa-picker :only="['solid']" v-model="value.icon">
                          <template v-slot:activator="{ on }">
                            <input id="icon" v-model="value.icon" @click="on" placeholder="Icon Class" type="text" />
                          </template>
                        </vfa-picker>

                        <div class="help text-danger" v-show="errors.has('icon')">
                          {{ errors.first("icon") }}
                        </div>
                      </div>
                      <div class="form-group required">
                        <label for="uri" class="control-label">URI</label>
                        <input
                          type="text"
                          id="uri"
                          name="uri"
                          v-model="value.uri"
                          class="form-control"
                          :class="{ 'has-error': errors.has('uri') }"
                          placeholder="Name"
                        />
                        <div class="help text-danger" v-show="errors.has('uri')">
                          {{ errors.first("uri") }}
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="roles" class="control-label">Roles</label>
                        <multiselect
                          track-by="name"
                          id="roles"
                          name="roles"
                          label="name"
                          v-model="value.roles"
                          value="id"
                          :options="pre.roles"
                          :searchable="true"
                          :multiple="true"
                          :close-on-select="true"
                          :show-labels="false"
                          :class="{ 'has-error': errors.has('roles') }"
                          placeholder="Pick a value"
                        ></multiselect>
                        <div
                          class="help text-danger"
                          v-show="errors.has('roles')"
                        >
                          {{ errors.first("roles") }}
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="permissions" class="control-label"
                        >Permissions</label
                        >
                        <multiselect
                          track-by="name"
                          id="permissions"
                          label="name"
                          v-model="value.permissions"
                          value="id"
                          :options="pre.permissions"
                          :searchable="true"
                          :multiple="true"
                          :close-on-select="true"
                          name="permissions"
                          :class="{ 'has-error': errors.has('permissions') }"
                          :show-labels="false"
                          placeholder="Pick a value"
                        ></multiselect>
                        <div
                          class="help text-danger"
                          v-show="errors.has('permissions')"
                        >
                          {{ errors.first("permissions") }}
                        </div>
                      </div>
                    </div>
                    <!-- /.card-body -->
                    <div class="">
                      <button type="submit" class="btn btn-common">Submit</button>

                      <button type="reset" class="btn btn-warning" @click="resetForm()"><i class="fas fa-undo"></i>
                        Reset
                      </button>
                    </div>
                  </form>

                  <!--              <pre>@{{ menuItems }}</pre>-->
                </div>
                <div class="card-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="content" v-if="has_permission === false">
      <div class="alert alert-danger alert-dismissible">
        <h5><i class="icon fas fa-ban"></i> Permission denied!</h5>
      </div>
    </section>
  </div>
</template>

<script>
import "he-tree-vue/dist/he-tree-vue.css"; // base style
import {
  Tree, // Base tree
  Fold,
  Check,
  Draggable, // plugins
  foldAll,
  unfoldAll,
  cloneTreeData,
  walkTreeData,
  getPureTreeData, // utils
} from "he-tree-vue";
import { Menu, CommonAPI } from "@/services/api";
import axios from "axios";

export default {
  name: "MenuCustomization",
  metaInfo: {
    title: "Menu Customization | Dotlines",
  },
  components: {
    Tree: Tree.mixPlugins([Draggable, Fold]),
  },
  data: () => ({
    is_loading: false,
    menuItems: [],
    flattenData: [],
    pre: {
      roles: [],
      permissions: [],
      menu_items_for_dropdown: {},
    },
    value: {},
    is_edit: false,
    update_id: null,
    is_collapsed: false,
    has_permission: true,
  }),
  mounted: function () {
    //console.log($.getPureTreeData);
    this.initialize();
  },
  methods: {
    async resetForm() {
      this.value = {};
      this.is_loading = true;
      this.refresh();
      this.is_loading = false;
    },
    handleSubmission() {
      let _this = this;
      this.$validator.validateAll().then((result) => {
        if (result) {
          _this.is_loading = true;
          if (!_this.checkFaPrefixExists(_this.value.icon)) {
            _this.value.icon='fa-'+_this.value.icon;
            console.log('check icon fa inside',_this.value.icon)
          }
          console.log('check icon fa',_this.value.icon)
          if (_this.is_edit) {
            Menu.update(this.update_id, this.value)
              .then((response) => {
                this.$toaster.success(response.message);
              })
              .catch((errors) => {
                //console.log('errors.response.data', errors);
                if (errors.status === 400) {
                  this.$toaster.error("Bad Request");
                }
                this.$setErrorsFromResponse(errors.data);
              })
              .finally(() => {
                _this.is_loading = false;
              });
          } else {
            Menu.store(this.value)
              .then((response) => {
                this.$toaster.success(response.message);
              })
              .catch((errors) => {
                // this.$toaster.error(errors.response.data);
                this.$setErrorsFromResponse(errors.data);
              })
              .finally(() => {
                _this.is_loading = false;
              });
          }
        }
      });
    },
    checkFaPrefixExists: function (value) {
      return value.includes("fa-");
    },
    edit: function (id) {
      this.getMenuDetail(id);
      this.update_id = id;
    },
    async getMenuDetail(id) {
      await Menu.getMenuDetail(id).then((menu_detail) => {
        this.value = menu_detail;
        this.is_edit = true;
      });
    },
    destroy: function (id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.is_loading = true;
            Menu.destroy(id)
              .then((response) => {
                this.$toaster.success(response.message);
                this.refresh();
              })
              .catch((error) => {
                this.$swal.fire("Failed!", "Failed to delete.", "error");
              })
              .finally(() => {
                this.is_loading = false;
              });
          }
        });
    },
    refresh() {
      this.getMenuItems();
    },
    getMenuItems: function () {
      Menu.getAllMenus().then((menus) => {
        this.menuItems = menus;
      });
    },
    updateOrder: function () {
      let orders = this.resetOrder(this.menuItems);
      this.menuItems = orders;
      this.is_loading = true;
      //this.flattenObject(this.menuItems);
      Menu.saveMenuOrder(this.menuItems)
        .then((response) => {
          this.$toaster.success(response.message);
          this.refresh();
        })
        .catch((error) => {
          this.$swal.fire("Failed!", "Failed to delete.", "error");
        })
        .finally(() => {
          this.is_loading = false;
        });
      //console.log('response::', this.flattenData);
    },
    resetOrder: function (items, parent = 0) {
      items.forEach((v, idx) => {
        items[idx].order = idx;
        items[idx].parent_id = parent;
        if (!_.isEmpty(v.children)) {
          this.resetOrder(v.children, v.id);
        }
      });
      return items;
    },
    flattenObject: function (items) {
      items.forEach((v, idx) => {
        this.flattenData.push(v);

        if (!_.isEmpty(v.children)) {
          this.flattenObject(v.children);
        }
      });
    },
    async initialize() {
      this.is_loading = true;
      /*this.params = this.$route.params;
      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true;
      }
      if (this.is_edit) {
        await this.departmentShow(this.params.id);
      }*/

      await this.getMenuItems();

      await Menu.getAllMenuForDropdown().then((dropDownMenus) => {
        this.pre.menu_items_for_dropdown = dropDownMenus;
      }).catch(error => {
        if (error.response.status === 403) {
          this.has_permission = false;
        }
        reject(error);
      });

      await CommonAPI.getPermissions().then((permissions) => {
        this.pre.permissions = permissions;
      });
      await CommonAPI.getRoles().then((roles) => {
        this.pre.roles = roles;
      });

      this.is_loading = false;
      //walkTreeData(this.menuItems, node => {this.$set(node, '$folded', true)})
    },
    expandAll() {
      walkTreeData(this.menuItems, (node) => {
        this.$set(node, "$folded", false);
      });
    },
    collapseAll() {
      walkTreeData(this.menuItems, (node) => {
        this.$set(node, "$folded", true);
      });
    },
    toggleTreeView: function () {
      if (this.is_collapsed) {
        this.is_collapsed = false;
        this.expandAll();
      } else {
        this.is_collapsed = true;
        this.collapseAll();
      }
    },
    getNestedChildren: function (arr, parent) {
      var out = [];
      for (var i in arr) {
        if (arr[i].parent === parent) {
          var children = getNestedChildren(arr, arr[i].id);
          if (children.length) {
            arr[i].children = children;
          }
          out.push(arr[i]);
        }
      }
      return out;
    },
  },
};
</script>
